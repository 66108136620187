import { ChangeDetectorRef, Component, OnInit, HostListener } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from "@angular/router";
import { Subscription } from 'rxjs';
import { LogingServiceService } from "../app/Pages/login-sign-up/loging-service.service"
import { CookieServices } from "./cookie.service"
import { ApiService } from './core/services/api.service';
import { GenericService } from './core/services/generic.service';
import * as AOS from 'aos';
import { ToastService } from './core/ngb-toast/toast.service';
import { TokenDetails } from './core/models/TokenDetails';
import {MatIconRegistry} from '@angular/material/icon';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'mopp-web-site';
  isLogin: boolean = true;
  showSpinner: boolean = false;
  subscription: Subscription;
  isLoading: boolean = false;
  isScrolled: any;
  isNavShow: any;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event?: any) {
    const number = window.scrollY;
    if (number > 0) {
      this.isScrolled = true;
    } else {
      this.isScrolled = false;
    }
  }

  constructor(
    private router: Router,
    private service: LogingServiceService,
    private cookie: CookieServices,
    private generic: GenericService,
    private matIconReg: MatIconRegistry,
    private cdRef: ChangeDetectorRef,
    private apiService: ApiService,
    public toastService: ToastService
  ) {
    this.subscription = this.generic.spinerObs.subscribe(event => {
      this.showSpinner = event;
      this.cdRef.detectChanges();
    });
    this.router.events.subscribe((event: any) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.isLoading = true;
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
           // Check if the URL contains /app
           if (this.router.url.includes('/app')) {
            // Remove /app from the URL
            const newUrl = this.router.url.replace('/app', '');
            this.router.navigateByUrl(newUrl);
          }
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
          break;
        }
        default: {
          break;
        }
      }
    });

  }

  ngOnInit() {
    AOS.init();
    this.service.getLoginInfo.subscribe(data => {
      if (data) {
        this.isLogin = true;
        this.cdRef.detectChanges();
        this.generic.getCartCount();
      } else {
        this.isLogin = false;
        this.cdRef.detectChanges();
      }
    });
    if (!this.service.isLoggedIn()) {
      let rememberToken = this.cookie.getCookie("refreshToken")
      let rememberMe = localStorage.getItem('rememberMe') == 'true' ? true : false;
      if (rememberToken && rememberMe) {
        this.service.refreshToken(rememberToken).subscribe((data: any) => {
          if (data && data['role'] === 'CUSTOMER') {
            let tokenDetails: TokenDetails = new TokenDetails(
              data.tokens.access,
              data.tokens.refresh,
              data.email,
              data.role,
              data?.id,
              data?.username,
              data.firstname,
              data.lastname,
              data?.['permission_rights'][0],
              data?.profile_pic,
              data?.city
            );
            this.service.setLoginDetailsToLocalStorage(tokenDetails);
            this.generic.getPermissions().subscribe(
              success => {
                this.generic.autoLogin();
              }
            );
          } else {
            this.service.backToLogin();
          }
        }, error => {
          this.service.backToLogin();
        })
      }
    }

    this.matIconReg.setDefaultFontSetClass('material-symbols-outlined');
  }

}
